


































































































import { debounceProcess } from "@/helpers/debounce";
import {
  OptionModel,
  ResponsePagination,
} from "@/models/constant/interface/common.interface";
import {
  ContactData,
  ResponseListContactData,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import Vue from "vue";

type Row = { key: string } & Omit<ContactData, "key">;

export default Vue.extend({
  name: "ListContact",
  data() {
    this.reponseSearchInput = debounceProcess(this.reponseSearchInput, 200);
    return {
      totalElements: 0,
      page: 1,
      limit: 10,
      search: "customer~true",
      direction: "",
      activeTabPane: "Customer",
      columnTabPane: [
        { label: "lbl_customer", value: "Customer" },
        { label: "lbl_supplier", value: "Supplier" },
        { label: "lbl_employee", value: "Employee" },
      ] as OptionModel[],
      dataSource: [] as Array<Row>,
      loadingTable: false,
      loadingDownload: false,
      columnsTable: [
        {
          title: this.$t("lbl_number_long"),
          dataIndex: "customerNumber",
          key: "customerNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_full_name"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_phone_number"),
          dataIndex: "phoneNumber",
          key: "phoneNumber",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_email"),
          dataIndex: "email",
          key: "email",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" },
        },
        {
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["update"],
          width: 120,
          align: "center",
          fixed: "right",
        },
      ],
    };
  },
  created() {
    this.getListContact();
  },
  methods: {
    handleDownload() {
      const params: RequestQueryParamsModel = {
        search: `${this.activeTabPane.toLocaleLowerCase()}~true`,
      };
      this.loadingDownload = true;
      contactServices
        .downloadReportContact(params)
        .then(dataBlob => {
          if (dataBlob) {
            const url = window.URL.createObjectURL(new Blob([dataBlob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report_contact.xlsx"); //or any other extension
            link.click();
          }
        })
        .finally(() => (this.loadingDownload = false));
    },
    handleEdit(record: Row) {
      this.$router.push(`/contact/update/${record.id}`);
    },
    handleChangeMenu(value) {
      this.page = 1;
      this.activeTabPane = value;
      this.search = `${this.activeTabPane.toLocaleLowerCase()}~true`;
      this.getListContact();
      if (value === "Customer") {
        this.columnsTable[0] = {
          title: this.$t("lbl_number_long"),
          dataIndex: "customerNumber",
          key: "customerNumber",
          scopedSlots: { customRender: "nullable" },
        };
      } else if (value === "Supplier") {
        this.columnsTable[0] = {
          title: this.$t("lbl_number_long"),
          dataIndex: "supplierNumber",
          key: "supplierNumber",
          scopedSlots: { customRender: "nullable" },
        };
      } else {
        this.columnsTable[0] = {
          title: this.$t("lbl_number_long"),
          dataIndex: "employeeNumber",
          key: "employeeNumber",
          scopedSlots: { customRender: "nullable" },
        };
      }
      this.columnsTable = this.columnsTable.slice();
    },
    reponseSearchInput(response): void {
      if (response)
        this.search = `${this.activeTabPane.toLocaleLowerCase()}~true_AND_firstName~*${response}*_OR_lastName~*${response}*_OR_phoneNumber~*${response}*_OR_email~*${response}*_OR_${this.activeTabPane.toLocaleLowerCase()}Number~*${response}*`;
      else this.search = `${this.activeTabPane.toLocaleLowerCase()}~true`;
      this.page = 1;
      this.getListContact();
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListContact();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListContact();
    },
    resetFilter(): void {
      this.direction = "";
      this.search = `${this.activeTabPane.toLocaleLowerCase()}~true`;
      this.page = 1;
      this.getListContact();
    },
    getListContact() {
      let params = {
        limit: this.limit,
        page: this.page - 1,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
      this.dataSource = [];
      this.loadingTable = true;
      contactServices
        .listContactData(params)
        .then((res: ResponseListContactData) => {
          this.dataSource = res.data.map<Row>(item => ({
            ...item,
            key: item.id ?? "",
          }));
          this.totalElements = res.totalElements;
        })
        .finally(() => (this.loadingTable = false));
    },
    createNew() {
      this.$router.push("/contact/create");
    },
  },
});
